import { PaymentPlanStarted } from '@/types/Eligibility'
import { z } from 'zod'

export const BillingAddress = z.object({
  id: z.string(),
  created: z.number(),
  city: z.string().nullable(),
  country: z.string().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  postal_code: z.string().nullable(),
  line1: z.string().nullable(),
  line2: z.string().nullable(),
  phone: z.string().nullable().optional(),
  state_province: z.string().nullable(),
  title: z.string().nullable().optional(),
  company: z.string().nullable().optional(),
  county_sublocality: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
})

export type BillingAddress = z.infer<typeof BillingAddress>

// we filter checkout payment type only with data we need at time

const Card = z.object({
  verified: z.boolean(),
})

export type Card = z.infer<typeof Card>

export const Customer = z.object({
  id: z.string(),
  created: z.number(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  addresses: z.array(BillingAddress).nullable().optional(),
  email: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  card: Card.nullable().optional(),
})

export type Customer = z.infer<typeof Customer>

export const State = z.enum([
  'not_ready',
  'not_started',
  'scored_no',
  'scored_maybe',
  'scored_yes',
  'in_progress',
  'paid',
  'late',
  'default',
])

export type State = z.infer<typeof State>

export const PaymentStatus = z.enum(['validated', 'pending', 'expired', 'denied'])

export type PaymentStatus = z.infer<typeof PaymentStatus>

const PaymentRequirementNameKeys = [
  'customer_info',
  'contact_information',
  'valid_payment_method',
  'id_video_verification',
  'proof_of_address',
  'phone_verification',
  'contract_signature',
  'unknown_requirement',
] as const
/*
Missing requirement names compared to Checkout:
'banking_data_verification'
'three_ds_auth'
*/

const PaymentRequirementStepKeys = [
  ...PaymentRequirementNameKeys,
  'expired',
  'not_started',
  'converted',
  'rejected',
  'payment_validation',
  'three_ds_auth',
  'payment_not_configured',
  'banking_data_verification_BI',
  'banking_data_verification_declaration',
  'error_step',
  'unknown_step',
] as const

export const PaymentRequirementName = z.enum([
  ...PaymentRequirementNameKeys,
  'banking_data_verification',
])

export type PaymentRequirementName = z.infer<typeof PaymentRequirementName>

export const PaymentRequirementStep = z.enum(PaymentRequirementStepKeys)

export type PaymentRequirementStep = z.infer<typeof PaymentRequirementStep>

export const Requirement = z.object({
  name: PaymentRequirementName,
  is_met: z.boolean(),
  reason: z.string().optional(),
  details: z
    .object({
      banking_provider: z.enum(['customer_financial_declaration', 'banking_aggregator']).optional(),
    })
    .optional()
    .nullable(),
})

export type Requirement = z.infer<typeof Requirement>

export type PaymentData = {
  data: ApiPayment[]
}

export const PaymentOrder = z.object({
  created: z.number(),
  id: z.string(),
  data: z.record(z.string(), z.unknown()),
  merchant_reference: z.string().nullable(),
  comment: z.string().nullable(),
})

export type PaymentOrder = z.infer<typeof PaymentOrder>

export const User = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email(),
})

export type User = z.infer<typeof User>

export enum PaymentOrigin {
  /** Payment initiated from an e-commerce shop */
  online = 'online',
  /** Payment initiated on PoS, sent to mobile phone via SMS */
  posSms = 'pos_sms',
  /** Payment initiated on PoS, handled on the merchant's device */
  posDevice = 'pos_device',
  /** Payment initiated on PoS, sent by email or other means */
  posLink = 'pos_link',
  /** Payment initiated on PoS, sent to physical payment terminal */
  posTerminal = 'pos_terminal',
}

export const PaymentOriginSchema = z.nativeEnum(PaymentOrigin)

export const Refund = z.object({
  id: z.string(),
  created: z.number(),
  amount: z.number(),
})

export type Refund = z.infer<typeof Refund>

export const ApiPayment = z.object({
  created: z.number(),
  updated: z.number(),
  customer: Customer,
  customer_fee: z.number(),
  deferred_days: z.number(),
  deferred_months: z.number(),
  id: z.string(),
  url: z.string(),
  installments_count: z.number().nullable(),
  payment_plan: z.array(PaymentPlanStarted),
  purchase_amount: z.number(),
  return_url: z.string().nullable(),
  state: State,
  expired_at: z.number().nullable(),
  requirements: z.array(Requirement),
  orders: z.array(PaymentOrder),
  customer_target_fee: z.number().optional(),
  custom_data: z.record(z.string(), z.unknown()).optional(),
  billing_address: BillingAddress.nullable(),
  seller: User.nullable(),
  /**
   * 2 letters country code
   * Defined in: https://github.com/alma/main/blob/f0067f40f34034c1964ea198344ad03da23a72a2/model/enums.py#L69
   */
  transaction_country: z.string(),
  origin: PaymentOriginSchema,
  merchant_target_fee: z.number().optional(),
  merchant_id: z.string(),
  refunds: z.array(Refund),
  merchant_display_name: z.string().optional(),
})

export type ApiPayment = z.infer<typeof ApiPayment>

export const Payment = ApiPayment.and(
  z.object({
    status: PaymentStatus,
    cancelable: z.boolean(),
    requirementStep: PaymentRequirementStep,
  })
)

export type Payment = z.infer<typeof Payment>

export type PaymentOnTerminal = Payment & { origin: PaymentOrigin.posTerminal }

export function isPaymentOnTerminal(payment: Payment | undefined): payment is PaymentOnTerminal {
  return payment?.origin === PaymentOrigin.posTerminal
}
